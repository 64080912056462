import type { IHitResult } from '../components/SearchResults/types';
import type { ProjectSchema as Project } from '@readme/backend/models/project/types';
import type { RefObject } from 'react';
import type React from 'react';

import algoliasearch from 'algoliasearch/lite';
import { useState, useEffect, useMemo, useCallback, useContext } from 'react';

import { VersionContext } from '@core/context';
import useEnvInfo from '@core/hooks/useEnvInfo';
import { useMetricsAPIFetcher } from '@core/hooks/useMetricsAPI';
import { useSearchStore } from '@core/store';

import { ClientTypes } from '../types';

interface SearchHookProps {
  appId: string;
  isReady?: boolean;
  project: Project;
  rootNode: RefObject<HTMLDivElement>;
  searchApiKey: string;
  searchInput: RefObject<HTMLInputElement>;
}

export default function useSearch({
  appId,
  searchApiKey,
  project,
  rootNode,
  searchInput,
  isReady = false,
}: SearchHookProps) {
  const [refinementListOpen, setRefinementListOpen] = useState(true);
  const [prompt] = useSearchStore(store => [store.prompt]);
  const [initAlgolia, setAlgoliaInit] = useState(ClientTypes.PROXY);
  const [isLive, setLive] = useState(false);
  const { version } = useContext(VersionContext);
  const metricsFetch = useMetricsAPIFetcher({ version });

  const { isClient } = useEnvInfo();
  /**
   * Wrap the Algolia client in a conditional, so we don't hit their
   * API on empty searches to limit unnecessary requests. More here:
   * https://algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react
   */
  const algoliaClient = useMemo(
    () => ({
      LIVE: algoliasearch(appId, searchApiKey),
      PROXY: {
        search: (requests: IHitResult[]) =>
          Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              page: 0,
              processingTimeMS: 0,
            })),
          }),
      },
    }),
    [appId, searchApiKey],
  );

  useEffect(() => {
    if ((prompt.length > 2 || !project.owlbot.enabled) && isLive) {
      setAlgoliaInit(ClientTypes.LIVE);
    } else {
      setAlgoliaInit(ClientTypes.PROXY);
    }
  }, [prompt, isLive, project.owlbot.enabled]);

  useEffect(() => {
    let MQ: MediaQueryList;
    const toggleRefinementList = (e: MediaQueryListEvent) => setRefinementListOpen(!e?.matches);
    if (isClient && 'matchMedia' in window) {
      MQ = window.matchMedia('(max-width: 700px)');
      MQ.addEventListener('change', toggleRefinementList);
    }

    return () => {
      if (MQ) MQ.removeEventListener('change', toggleRefinementList);
    };
  }, [isClient]);

  const handleKeyboardEvent = useCallback(
    e => {
      // keyboard event handling should fail silently
      try {
        const { key } = e;

        const search = rootNode.current as unknown as HTMLElement;
        if (search == null) return;

        const searchBox = search.querySelector('input[type=search]') as HTMLInputElement;
        const searchHits = search.querySelector('.SearchResults-list') as HTMLDivElement;
        const searchPage = search.querySelector('footer');
        const focused = document.activeElement;

        if (searchBox === focused) {
          if (key === 'ArrowDown' || key === 'ArrowUp' || key === 'Enter') {
            const firstlastChild = key === 'ArrowUp' ? 'lastElementChild' : 'firstElementChild';
            (searchHits?.[firstlastChild] as HTMLAnchorElement)?.focus();
            e.preventDefault();
          }
        } else if (!(searchPage && searchPage.matches(':focus-within')) && key === 'Tab' && e.shiftKey) {
          searchBox.focus();
          e.preventDefault();
        } else if (searchHits && !searchHits.matches(':focus-within') && (key === 'ArrowDown' || key === 'ArrowUp')) {
          (searchHits[key === 'ArrowUp' ? 'lastElementChild' : 'firstElementChild'] as HTMLAnchorElement)?.focus();
          e.stopPropagation();
          e.preventDefault();
        }
      } catch (error) {
        // do nothing
      }
    },
    [rootNode],
  );

  useEffect(() => {
    if (!isReady && isLive) setLive(false);
  }, [isReady, isLive]);

  // We block attempts to use Algolia's actual API when our search modal is closed to reduce unnecessary traffic
  // Once modal is in focus, use the live Algolia client
  const handleFocusIn = useCallback(() => {
    if (isClient && !isLive && isReady) setLive(true);
  }, [isClient, isLive, isReady]);

  const handleRefinementBlur = useCallback(
    (e: React.KeyboardEvent<HTMLLIElement>) => {
      if (!isClient) return;

      const search = rootNode.current as unknown as HTMLElement;
      if (search == null) return;

      const searchBox = search.querySelector('input[type=search]') as HTMLInputElement;
      searchBox?.focus();
      searchBox?.select();
      e.preventDefault();
      e.stopPropagation();
    },
    [isClient, rootNode],
  );

  // Construct a documentation metrics for this project on result selection || blur
  const handleResultSelection = useCallback(
    async ({ term, link }: { link?: string; term?: string }) => {
      const body = {
        search: term || searchInput?.current?.value,
        link: (link || '').split('#')[0],
      };
      try {
        await metricsFetch({
          path: 'create/search',
          method: 'POST',
          body,
        });
      } catch {
        // Fail silently
      }

      if (link) window?.location?.assign(link);
    },
    [metricsFetch, searchInput],
  );

  return {
    algoliaClient,
    initAlgolia,
    refinementListOpen,
    handleFocusIn,
    handleKeyboardEvent,
    handleRefinementBlur,
    handleResultSelection,
  };
}
